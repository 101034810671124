<template>
  <div id="printArea">
    <CRow>
      <CCol sm="4">
        <h4 class="text-uppercase">Meter Consumptions Details</h4>
      </CCol>
      <CCol sm="8">
        <form v-on:submit.prevent="filterConsumption()">
          <CRow class="">
            <CCol sm="3" class="pr-0">
              <CInput
                  type="date"
                  v-model="params.start_date"
              />
            </CCol>
            <CCol sm="3" class="pr-0">
              <CInput
                  type="date"
                  v-model="params.end_date"
              />
            </CCol>

            <CCol sm="2">
              <button type="submit" class="btn btn-success w-100">Submit</button>
            </CCol>
            <CCol sm="3">
              <router-link class="btn btn-warning w-100" :to="{name:'MeterConsumptions'}">
                Back
              </router-link>
            </CCol>
          </CRow>
        </form>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow v-if="meterConsumer.customer_account">
            <CCol sm="5">
              <table>
                <tr>
                  <td class="py-1">A/c No :</td>
                  <td class="py-1">{{ meterConsumer.customer_account.account_number }}</td>
                </tr>
                <tr>
                  <td class="py-1">Name :</td>
                  <td class="py-1">{{ meterConsumer.customer_account.name }}</td>
                </tr>
                <tr>
                  <td class="py-1">Address :</td>
                  <td class="py-1">{{ meterConsumer.customer_account.address }}</td>
                </tr>
                <tr>
                  <td class="py-1"></td>
                  <td class="py-1">{{ meterConsumer.customer_account.address2 }}</td>
                </tr>
                <tr>
                  <td class="py-1">Contact :</td>
                  <td class="py-1">{{ meterConsumer.customer_account.mobile_number }}</td>
                </tr>
              </table>
            </CCol>
            <CCol sm="7">
              <CRow>
                <CCol sm="6">
                  <table>
                    <tr>
                      <td class="py-1">Class :</td>
                      <td class="py-1">{{ meterConsumer.customer_account.class }}</td>
                    </tr>
                    <tr>
                      <td class="py-1">Category :</td>
                      <td class="py-1">{{ meterConsumer.customer_account.category }}</td>
                    </tr>
                    <tr>
                      <td class="py-1">Meter No :</td>
                      <td class="py-1">{{ meterConsumer.meter_number }}</td>
                    </tr>
                    <tr>
                      <td class="py-1">Status :</td>
                      <td class="py-1">{{ meterConsumer.meter_status }}</td>
                    </tr>
                    <tr>
                      <td class="py-1">Ward No :</td>
                      <td class="py-1">{{ meterConsumer.customer_account.ward_number }}</td>
                    </tr>
                  </table>
                </CCol>
                <CCol sm="6">
                  <table>
                    <tr>
                      <td class="py-1">Size :</td>
                      <td class="py-1">{{ meterConsumer.connection_size }}</td>
                    </tr>
                    <tr>
                      <td class="py-1">Domestic :</td>
                      <td class="py-1">{{ meterConsumer.customer_account.domestic }}</td>
                    </tr>
                    <tr>
                      <td class="py-1">Non-Domestic :</td>
                      <td class="py-1">{{ meterConsumer.customer_account.non_domestic }}</td>
                    </tr>
                    <tr>
                      <td class="py-1">MI :</td>
                      <td class="py-1">{{ meterConsumer.customer_account.mi_code }}</td>
                    </tr>
                  </table>
                </CCol>
              </CRow>
            </CCol>
          </CRow>

          <div class="table-responsive">
            <table class="table table-borderless">
              <thead>
              <tr class="text-center">
                <th scope="col">SL</th>
                <th class="font-weight-bold">Meter Reading</th>
                <th class="font-weight-bold">Reading Date</th>
                <!--                  <th class="font-weight-bold">Created At</th>-->
              </tr>
              </thead>
              <tbody v-if="consumptions.length">
              <tr
                  class="text-center"
                  v-for="(consumption, index) in consumptions"
                  :key="consumption.id"
              >
                <td scope="row">{{ meta.from + index }}</td>
                <td class="py-1">{{ consumption.water_unit }}</td>
                <td class="py-1">{{ consumption.reading_date_time | dateTimeFormat}}</td>
                <!--                  <td class="py-1">{{ dateFormat(consumption.created_at) }}</td>-->
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="3">
                  <h5 class="text-center">Data Not Available.</h5>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <CPagination
              v-if="consumptions.length"
              align="center"
              :pages="rows"
              :active-page.sync="params.currentPage"
              @update:activePage="handlePageChange"
          />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment/moment";

export default {
  name: "consumptionsConsumptionsView",
  data: () => {
    return {
      params: {
        start_date: moment().clone().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        meterId: "",
        currentPage: 1
      },
      isMobile: Boolean,
      isMobileOnly: Boolean,
      breadcrumbs: [{text: "Home", path: "/dashboard", isActive: false}, {
        text: "Meter Consumptions",
        path: "/meter-consumptions",
        isActive: false
      }, {text: "Consumptions Details", path: "", isActive: true}]
    };
  },

  computed: {
    ...mapGetters("Meter", [
      "meterConsumer",
      "consumptions",
      "rows",
      "perPage",
      "meta",
      "consumptionObj",
      "isLoading",
    ]),
  },
  methods: {
    filterConsumption() {
      this.$router.replace({name: "Consumptions", query: this.params});
      this.$store.dispatch("Meter/getMeterConsumptions", this.params);
    },
    handlePageChange(value) {
      this.params.currentPage = value;
      this.$router.replace({name: "Consumptions", query: this.params});
      this.$store.dispatch("Meter/getMeterAndConsumerByMeterId", this.params.meterId);
      this.$store.dispatch("Meter/getMeterConsumptions", this.params);
    },
  },
  mounted() {
    this.params.currentPage = this.$route.query.currentPage ? parseInt(this.$route.query.currentPage) : 1;
    this.params.start_date = this.$route.query.start_date ? this.$route.query.start_date : this.params.start_date;
    this.params.end_date = this.$route.query.end_date ? this.$route.query.end_date : this.params.end_date;
    this.params.meterId = this.$route.params.id;
    this.$router.replace({name: "Consumptions", query: this.params});
    this.$store.dispatch("Meter/getMeterAndConsumerByMeterId", this.params.meterId);
    this.$store.dispatch("Meter/getMeterConsumptions", this.params);
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>

<style scoped>
.card {
  border: none;
  padding: 15px 15px 0;
}

tbody:before {
  content: "@";
  display: block;
  /* line-height: 30px; */
  text-indent: -99999px;
}
</style>

<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }

  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }

    tbody {
      font-size: 13px;

      tr {
        color: #000;

        td {
          padding: 0.75rem 0 0.75rem 0.75rem;

          button {
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>
